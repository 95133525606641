// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/anton/sites/prod/zion-infotech/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("/Users/anton/sites/prod/zion-infotech/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("/Users/anton/sites/prod/zion-infotech/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-home-js": () => import("/Users/anton/sites/prod/zion-infotech/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/Users/anton/sites/prod/zion-infotech/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("/Users/anton/sites/prod/zion-infotech/src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-services-js": () => import("/Users/anton/sites/prod/zion-infotech/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-technology-js": () => import("/Users/anton/sites/prod/zion-infotech/src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */)
}

